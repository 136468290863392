<template>
  <div class="intro-page">
    <div class="intro-container">
      <h1 class="intro-title">心理健康素养测评问卷</h1>
      <div class="content1">亲爱的同事：<br /></div>

      <div class="content">
        心理健康素养指运用心理健康知识、技能和态度,
        保持和促进本人及他人心理健康的能力。
      </div>
      <br />
      <div class="content">
        下面是关于心理健康相关认识的描述，请根据题目描述，结合自己当前的认识做出选择。
      </div>
      <br />

      <button class="start-button" @click="startAssessment">开始测评</button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  data() {
    return {
      userId: "",
    };
  },
  methods: {
    startAssessment() {
      if (!this.userId || this.userId == "undefined") {
        setTimeout(() => {
          this.$router.push("/cp/xinlijiankang/login");
        }, 1000);
        return;
      }
      // 跳转到测评页面
      this.$router.push("/CP/xinlijiankang/answer");
    },
  },
  created() {
    this.userId = localStorage.getItem("userId");
  },
};
</script>

<style scoped>
/* 引导页整体布局 */
.intro-page {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
  box-sizing: border-box;
}

.intro-container {
  max-width: 720px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  box-sizing: border-box;
  text-align: center;
}

/* 标题样式 */
.intro-title {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

/* 正文内容样式 */
.intro-text {
  line-height: 1.8;
  color: #333;
  text-align: left;
  margin-bottom: 10px;
}

/* 按钮样式 */
.start-button {
  background-color: #76c7c0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.start-button:hover {
  background-color: #5ba9a1;
}

/* 响应式处理 */
@media (max-width: 768px) {
  .intro-container {
    padding: 20px;
  }
  .intro-title {
    font-size: 1.5em;
  }
  .start-button {
    padding: 8px 16px;
  }
}
.content {
  text-align: left;
  text-indent: 20px;
  line-height: 25px;
}
.content1 {
  text-align: left;
  margin-bottom: 20px;
  line-height: 25px;
}
.jiacu {
  /* 加粗 */
  font-weight: bold;
}
</style>
